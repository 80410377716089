import React, {Suspense, useState, useEffect, useContext} from 'react';

//config

import {animateScroll as scroll} from 'react-scroll'
import { useHistory, useLocation } from 'react-router-dom';

import Loader from '../../components/navigation/components/Loader';

import { store } from '../../core/store/store';
import { Actions } from '../../core/actions/actions';
import { useMemo } from 'react';

// NAVIGATION
const Head = React.lazy(() => import('../../components/navigation/components/Head'));
const Header = React.lazy(() => import('../../components/navigation/components/Header'));
const Footer = React.lazy(() => import('../../components/navigation/components/Footer'));

// Sections
const Unete = React.lazy(() => import('../../components/sections/Unete'));
const Interactivo = React.lazy(() => import('../../components/sections/Interactivo'));
const Nosotros = React.lazy(() => import("../../components/sections/Nosotros"));
const ZonaInteraccion = React.lazy(() => import('../../components/sections/ZonaInteraccion'));
const Buscar = React.lazy(() => import('../../components/sections/Buscar'));
const Proyecto = React.lazy(() => import('../../components/sections/Proyecto'));
const Cocreador = React.lazy(() => import('../../components/sections/Cocreador'));
const NotasDestacadas = React.lazy(() => import('../../components/sections/NotasDestacadas'));

// wrappers
const Collapse = React.lazy(() => import("../../components/wrappers/Collapse"))

let HomePage = (props) => {
  const [isDark] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const [openCollapse, setOpenCollapse] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [fullLoaded, setFullLoaded] = useState(false);

  const globalState = useContext(store);
  const { 
    section__home, 
    notas_destacadas, 
    notas_seccion, 
    secciones, 
    seccion__zona_de_interaccion, 
    seccion__nosotros,
    seccion__unete,
    seccion__cocreadores,
    seccion__proyecto,
    ediciones,
    cocreadores } = globalState.state; 
  const { dispatch } = globalState;


  useEffect(() => {    
    if(!loaded){
      const getSections = async () => {
        console.log("::HOME: LOAD CONTENT::")

        if(!section__home.length) {
          const secs = await Actions.getSectionHome();
          dispatch(secs);
        }
        setLoaded(true);

      }

      getSections();
    }
  },[ 
      section__home,  
      loaded, 
      dispatch,
    ]);

  const getSectionIndex = useMemo(() => (tipo) => {  
    let itemIndex = 0;
    const mappedIndex = section__home.map(item => { 
      if(item.tipo === tipo) return true;
      return false
      }).indexOf(true);
  
      itemIndex = mappedIndex >= 0 ? mappedIndex : 0;

      return itemIndex
  },[section__home])  
  

  useEffect(() => {
    let open = new Array(section__home.length);

    if(section__home.length) setOpenCollapse(open);
    if(notas_destacadas.length && notas_seccion.length && secciones.length) open[getSectionIndex("secciones")] = true;
    if(seccion__zona_de_interaccion.id) open[getSectionIndex("zona_de_interaccion")] = true;
    if(seccion__unete.id) open[getSectionIndex("unete")] = true;
    if(seccion__nosotros.id) open[getSectionIndex("portal")] = true;

    setOpenCollapse(open);

    if(!fullLoaded 
      && seccion__nosotros.id  
      && ediciones.length > 0 
      && cocreadores.length > 0  
      && section__home.length > 0  
      && seccion__cocreadores.id  
      && seccion__proyecto.id
      ){
        setFullLoaded(true);
      }
    

  },[ notas_destacadas,
      notas_seccion, 
      secciones,
      ediciones, 
      cocreadores,
      section__home,
      seccion__cocreadores,
      seccion__zona_de_interaccion,
      seccion__proyecto,
      seccion__unete,
      seccion__nosotros,
      fullLoaded,
      getSectionIndex,
    ]);

  useEffect(() => {
    const goToPrev = () => {
      const hash = location.hash;
      let ele = "body"
  
      if((hash && hash !=="") && (history.action !== "PUSH")){
          let itemIndex = getSectionIndex(hash.substring(1));
          ele = hash;

          if(hash.match(/secciones=/)){
            console.log("es secciones selected")
            ele = "#secciones";
            itemIndex = 1;
          }
  
          if(hash.match(/buscar=/)){
            console.log("es buscar selected")
            ele = "#buscar"
            itemIndex = 3;
          }
      
          const header = document.querySelector('header');
          const headerHeight = header ? header.offsetHeight - 50: 0;
          const elem = document.querySelector(ele);
          if(elem){
            const eletop = elem.getBoundingClientRect().top + window.scrollY;
            const to = eletop - headerHeight > 0 ? eletop - headerHeight : eletop;      
            scroll.scrollTo(to);
            const open = openCollapse;
            open[itemIndex] = true;
            setOpenCollapse(open);
          }
      }
    }

    if(fullLoaded){
      goToPrev();
    }
  }, [fullLoaded, location.pathname, location.hash, history.action, section__home, getSectionIndex, openCollapse])


  if(!loaded){
    return <Loader type="page"></Loader>
  }
  
  const renderSection = (type, index) => {
    switch(type){
      case 'interactivo':
        return <Interactivo/>
      case 'secciones':
        return <NotasDestacadas />
      case 'zona_de_interaccion':
        return <ZonaInteraccion/>
      case 'buscar':
        return <Buscar type="compact"/> 
      case 'cocreadores':
        return <Cocreador/>
      case 'proyecto_magma':
        return <Proyecto />
      case 'unete':
        return <Unete/>
      case 'portal':
        return  <Nosotros />
      default:
        return false  
    }
  }

  return (
    <div className={`homepage-container`}>
      <Suspense fallback={<Loader></Loader>}><Head page="home" /></Suspense>
      <Suspense fallback={<Loader></Loader>}>
        <Header type="full" 
          isDark={isDark}
          />
      </Suspense>
      
      <main id="main">
        {section__home.map((sec, key) => {
            return (
              <Suspense key={key} fallback={<Loader></Loader>}>
                <Collapse 
                  title={sec.titulo}
                  className={`collapse-${sec.tipo}`}
                  mobileClosed={false}
                  isSection={true}
                  forceOpen={openCollapse ? openCollapse[key] : false}
                  id={sec.tipo}
                  hideHeader={sec.tipo === "interactivo"}
                  isClosed={sec.tipo === "interactivo" ? false : true}
                  addScroll={loaded}
                  content={
                    <Suspense fallback={<Loader></Loader>}>
                        {renderSection(sec.tipo, key)}
                    </Suspense>
                  }
            ></Collapse>
              </Suspense>
            );
          })
        }
      </main>    
      <Suspense fallback={<Loader></Loader>}>
        <Footer type="full"/>
      </Suspense>
    </div>
  )
}

export default HomePage